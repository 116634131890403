// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alerts-tsx": () => import("/opt/build/repo/src/pages/alerts.tsx" /* webpackChunkName: "component---src-pages-alerts-tsx" */),
  "component---src-pages-cart-tsx": () => import("/opt/build/repo/src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-faqs-tsx": () => import("/opt/build/repo/src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payments-tsx": () => import("/opt/build/repo/src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */)
}

